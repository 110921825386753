import { Typography } from "@material-ui/core/styles/createTypography";

export const MHDSVariantStyles = {
  displayEmphasis: {
    fontSize: "2rem",
    lineHeight: 2.25,
    fontWeight: 600,
  },
  displaySubtle: {
    fontSize: "2rem",
    lineHeight: "2.25rem",
    fontWeight: 375,
  },
  titleLarge: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
    letterSpacing: "0.015rem",
    fontWeight: 600,
  },
  titleSmall: {
    fontSize: "1.25rem",
    lineHeight: "1.75rem",
    letterSpacing: "0.0125rem",
    fontWeight: 500,
  },
  emphasisLarge: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.01rem",
    fontWeight: 500,
  },
  emphasisMedium: {
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.00875rem",
    fontWeight: 500,
  },
  emphasisSmall: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.0075rem",
    fontWeight: 500,
  },
  standardLarge: {
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.01rem",
    fontWeight: 375,
  },
  standardMedium: {
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.00875rem",
    fontWeight: 375,
  },
  standardSmall: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.0075rem",
    fontWeight: 375,
  },
  labelLarge: {
    fontSize: "1.0rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.001rem",
    fontWeight: 400,
  },
  labelMedium: {
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.00875rem",
    fontWeight: 400,
  },
  labelSmall: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.0075rem",
    fontWeight: 400,
  },
  linkLarge: {
    fontSize: "1.0rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.001rem",
    textDecoration: "underline",
    fontWeight: 400,
  },
  linkMedium: {
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.00875rem",
    textDecoration: "underline",
    fontWeight: 400,
  },
  linkSmall: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.0075rem",
    textDecoration: "underline",
    fontWeight: 400,
  },
};

export const MHDSVariants = [
  "displayEmphasis",
  "displaySubtle",
  "titleLarge",
  "titleSmall",
  "emphasisLarge",
  "emphasisMedium",
  "emphasisSmall",
  "standardLarge",
  "standardMedium",
  "standardSmall",
  "labelLarge",
  "labelMedium",
  "labelSmall",
  "linkLarge",
  "linkMedium",
  "linkSmall",
];

export type MHDSVariant = keyof typeof MHDSVariantStyles;

export type MHDSTypography = Omit<Typography, MHDSVariant> &
  typeof MHDSVariantStyles;
