import { CalendarDate, CalendarDateTime } from "@internationalized/date";
import { GroupOutlined } from "@material-ui/icons";
import {
  ActionItemAssignees,
  AssigneeType,
  Filter,
  IField,
  NormalizedActionItem,
} from "@pillpal/api-types";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { AvatarData } from "components/system/Avatar";
import { axiosClientWithDateParsing } from "helpers/axiosClient";
import { useToast } from "infra/toasts";

import {
  ACTIVE_ACTION_ITEMS_QUERY_PARAM,
  AssignmentGroups,
  RESOLVED_ACTION_ITEMS_QUERY_PARAM,
} from "./constants";

/**
 * TODO: Remove this function and use parseDate from @internationalized/date
 * @deprecated Please use parseDate in @internationalized/date
 * Converts string date passed in "YYYY-MM-DD" format to CalendarDate object.
 */
export const convertStringToCalanderDate = (date: string): CalendarDate => {
  const splittedDate = date.split("-");
  const year = parseInt(splittedDate[0]!);
  const month = parseInt(splittedDate[1]!);
  const day = parseInt(splittedDate[2]!);

  return new CalendarDate(year, month, day);
};

/**
 * Converts native Date object to CaldendarDateTime object.
 */
export const convertDateToCalenderDateTime = (
  date: Date | string
): CalendarDateTime => {
  let convertedDate: Date;

  // In some cases dates are not passed with timestamps and can just be YYYY-MM-DD strings so we need to make sure we are not messing those dates up during the process.
  const dateRegexWithoutTimeStamps = /^\d{4}-\d{2}-\d{2}$/;
  if (typeof date === "string" && dateRegexWithoutTimeStamps.test(date)) {
    convertedDate = new Date(`${date}T00:00:00`);
  } else {
    convertedDate = new Date(date);
  }

  const year = convertedDate.getFullYear();
  // adding 1 to month because indexing for month is expected to be from 0 to 11
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
  const month = convertedDate.getMonth() + 1;
  const day = convertedDate.getDate();
  const hours = convertedDate.getHours();
  const minutes = convertedDate.getMinutes();
  const seconds = convertedDate.getSeconds();
  const miliseconds = convertedDate.getMilliseconds();

  return new CalendarDateTime(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    miliseconds
  );
};

/**
 * Remove the cached action item query param from local storage.
 */
export const resetCachedActionItemQueryParams = () => {
  localStorage.removeItem(ACTIVE_ACTION_ITEMS_QUERY_PARAM);
  localStorage.removeItem(RESOLVED_ACTION_ITEMS_QUERY_PARAM);
};

// A request to handle deleting of action item
export const deleteActionItemAPI = async (concernId: string) =>
  (await axiosClientWithDateParsing.delete(`/api/actionItems/${concernId}`))
    .data;

// Hook with react query to delete action item
export function useDeleteActionItem() {
  const toast = useToast();
  return useMutation<
    {
      message: string;
    },
    AxiosError,
    {
      concernId: string;
    }
  >({
    mutationFn: (req) => {
      const { concernId } = req;
      return deleteActionItemAPI(concernId);
    },
    onSuccess: () => {
      toast.success("Successfully deleted the action item");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
}

/**
 * Checks if the url parameter filters consists of group assignments in assigned to filter for action items dashboard.
 */
export const checkGroupAssignmentsInFilter = ({ value }: Filter): boolean =>
  value === AssignmentGroups.ASSIGNED_TO_ME ||
  value === AssignmentGroups.ASSIGNED_TO_CARETEAMS ||
  value === AssignmentGroups.ASSIGNED_TO_EVERYONE;

/**
 * Returns the required array of objects for assignee avatars.
 */
export const getAssigneeAvatars = (
  assignees: ActionItemAssignees[],
  assignedTo?: string[]
): AvatarData[] =>
  assignedTo
    ? assignees
        .filter(({ id }) => assignedTo.includes(id))
        .map((item) => ({
          id: item.id,
          firstName: item.firstName || "",
          lastName: item.lastName || "",
          fullName: item.fullName,
          Icon:
            item.type === AssigneeType.CARE_TEAM ? GroupOutlined : undefined,
        }))
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    : [];

/**
 * Will filter resolved and unresolved action items from the list of action items and return them.
 */
export const getFilteredActionItems = (actionItems: NormalizedActionItem[]) => {
  const resolved: NormalizedActionItem[] = [];
  const unresolved: NormalizedActionItem[] = [];

  actionItems.forEach((item) => {
    if (item.isResolved) {
      resolved.push(item);
    } else {
      unresolved.push(item);
    }
  });

  return {
    resolved,
    unresolved,
  };
};

// This function will convert the date to UTC date
// we created this function because we wanted to save the date in UTC format for scheduled action items
export const convertCalendarDateToUTCDate = (
  date: CalendarDateTime | undefined
) => {
  if (!date) {
    return undefined;
  }
  const utcDate = new Date(date.toString()).toUTCString();
  return new Date(utcDate).toISOString().slice(0, 19);
};

export const isFilterEnabled = (
  addPatientFields: IField[] = [],
  fieldName: string = ""
) => {
  if (!addPatientFields) return false;

  return addPatientFields.some((field) => field.name === fieldName);
};

export const getFieldTitle = (
  addPatientFields: IField[] = [],
  fieldName: string = ""
) => {
  if (!addPatientFields) return "";
  return (
    addPatientFields.find((field) => field.name === fieldName)?.title || ""
  );
};
