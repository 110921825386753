import { DropdownOptions } from "@pillpal/api-types";

// Local storage keys for action item query params.
export const ACTIVE_ACTION_ITEMS_QUERY_PARAM =
  "active-action-items-query-param";
export const RESOLVED_ACTION_ITEMS_QUERY_PARAM =
  "resolved-action-items-query-param";

export enum AssignmentGroups {
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
  ASSIGNED_TO_EVERYONE = "ASSIGNED_TO_EVERYONE",
  ASSIGNED_TO_CARETEAMS = "ASSIGNED_TO_CARETEAM",
}

export const assignmentGroupOptions: DropdownOptions<string> = [
  {
    label: "Assigned to Me",
    value: AssignmentGroups.ASSIGNED_TO_ME,
  },
  {
    label: "Assigned to Everyone",
    value: AssignmentGroups.ASSIGNED_TO_EVERYONE,
  },
  {
    label: "Assigned to Care Team",
    value: AssignmentGroups.ASSIGNED_TO_CARETEAMS,
  },
];
