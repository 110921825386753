import { LogEntry, LogsArray } from "../apis/logApi";
import { getScreenSize, getWindowSize } from "./helpers";

export type SendFn = (logs: LogsArray) => void;

/** Generic class that allows for batching log events to be sent later. */
export class BatchHelper {
  private logs: LogsArray;

  private readonly batchSize: number;

  constructor(
    private readonly send: SendFn,
    { batchSize = 25, intervalMs = 10000 } = {}
  ) {
    if (!send || batchSize <= 0 || intervalMs <= 0) {
      throw new Error("Invalid construction of batchLogger");
    }
    this.batchSize = batchSize;
    this.logs = [];

    // technically we might send more often than the interval, if we filled
    // the queue then the timer runs out - but we are okay with sending more often
    window.setInterval(() => {
      this.flush();
    }, intervalMs);
  }

  /** Queue a log entry, to be sent later. */
  public log = (logData: LogEntry, { flush = false } = {}) => {
    this.logs.push({
      ...logData,
      eventTime: new Date(),
      windowSize: getWindowSize(),
      screenSize: getScreenSize(),
    });
    if (this.logs.length >= this.batchSize || flush) {
      this._sendLogs();
    }
  };

  /** Exposing flush api to be used to flush all data * */
  public flush = () => {
    if (this.logs.length > 0) {
      this._sendLogs();
    }
  };

  /** Internal use only. Send all current logs and clear them from the queue. */
  private _sendLogs = () => {
    const logs = [...this.logs];
    this.logs = [];
    this.send(logs);
  };
}
