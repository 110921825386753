"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeType = exports.bloodPressureCodes = void 0;
exports.bloodPressureCodes = {
    BLOOD_PRESSURE_ROOT_CODE: "85354-9",
    SYSTOLIC: "8480-6",
    DIASTOLIC: "8462-4",
};
var CodeType;
(function (CodeType) {
    CodeType["ANSWER"] = "answer";
    CodeType["QUESTION"] = "question";
    CodeType["SCORE"] = "score";
    CodeType["ROOT"] = "root";
})(CodeType = exports.CodeType || (exports.CodeType = {}));
