/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { requestPasswordResetApi, resendAccountActivationApi } from "apis/auth";
import { AxiosError, AxiosResponse } from "axios";
import * as React from "react";

import { useStyles } from "./sectionStyles";

const strings = {
  email: "Email Address",
  submit: "Submit",
  login: "Sign in",
  error: "Something went wrong",
};

interface IForgotPasswordProps {
  onLogin: () => void;
  isActivationLink?: boolean;
}

export const ForgotPassword = ({
  onLogin,
  isActivationLink = false,
}: IForgotPasswordProps) => {
  const urlMessage = atob(
    new URLSearchParams(window.location.search).get("msg") || ""
  );
  const [username, setUsername] = React.useState<string>();
  const [message, setMessage] = React.useState<string>(
    (isActivationLink && urlMessage) || ""
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage("");
    setIsSubmitting(true);
    try {
      let res: AxiosResponse<{ message: string }>;
      if (isActivationLink) {
        res = await resendAccountActivationApi(username!);
      } else {
        res = await requestPasswordResetApi(username!);
      }
      setIsSubmitting(false);
      setMessage(res?.data?.message || strings.error);
    } catch (error) {
      setIsSubmitting(false);
      setMessage(
        (error as AxiosError<any>)?.response?.data?.message || strings.error
      );
    }
  };

  const styles = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <Container className={styles.container}>
        <Typography component="span" className={styles.error}>
          {message}
        </Typography>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <AccountCircleIcon />
          </Grid>
          <Grid item className={styles.inputItem}>
            <TextField
              variant="standard"
              label={strings.email}
              type="email"
              size="small"
              className={styles.input}
              onChange={(val) => setUsername(val.target.value)}
              required
            />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.button}
            disabled={isSubmitting}
          >
            {strings.submit}
          </Button>
          {isSubmitting && (
            <CircularProgress size={24} className={styles.buttonSpinner} />
          )}
        </div>
        {!isActivationLink && (
          <Link component="button" onClick={onLogin}>
            {strings.login}
          </Link>
        )}
      </Container>
    </form>
  );
};
