"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssigneeType = exports.CONCERN_UNDO_TIMER = exports.ActionItemParam = exports.ConcernResolvedLocation = exports.SEVERITIES_DEFAULT_VALUE = exports.severities = exports.validConcernStatuses = void 0;
exports.validConcernStatuses = [
    "Follow-Up",
    "Concerning",
    "No Concerns",
    "Low Concerns",
];
exports.severities = Object.freeze({
    CRITICAL: "Follow-Up",
    ADVISORY: "Concerning",
    MESSAGE: "Unanswered Message",
    INFO: "No Concerns",
    ARCHIVED: "Archived",
    UNCONSENTED: "Unconsented",
    UNVERIFIED: "Unverified",
    OPTED_OUT: "Opted Out",
    LOW_CONCERNS: "Low Concerns",
    LANDLINE_NUMBER: "Landline Number",
});
exports.SEVERITIES_DEFAULT_VALUE = Object.freeze({
    [exports.severities.CRITICAL]: "High Concern",
    [exports.severities.ADVISORY]: "Medium Concern",
    [exports.severities.LOW_CONCERNS]: "Low Concern",
    [exports.severities.INFO]: "No Action Items",
});
var ConcernResolvedLocation;
(function (ConcernResolvedLocation) {
    ConcernResolvedLocation["PATIENT_DASHBOARD"] = "patient-dashboard";
    ConcernResolvedLocation["ACTION_ITEMS_DASHBOARD"] = "action-items-dashboard";
    ConcernResolvedLocation["LIVE_CHAT"] = "live-chat";
})(ConcernResolvedLocation = exports.ConcernResolvedLocation || (exports.ConcernResolvedLocation = {}));
var ActionItemParam;
(function (ActionItemParam) {
    ActionItemParam["ACTIVE"] = "active";
    ActionItemParam["RESOLVED"] = "resolved";
    ActionItemParam["SCHEDULED"] = "scheduled";
    ActionItemParam["ALL"] = "all";
})(ActionItemParam = exports.ActionItemParam || (exports.ActionItemParam = {}));
exports.CONCERN_UNDO_TIMER = 5000; // Milliseconds
var AssigneeType;
(function (AssigneeType) {
    AssigneeType["USER"] = "User";
    AssigneeType["CARE_TEAM"] = "Care Team";
})(AssigneeType = exports.AssigneeType || (exports.AssigneeType = {}));
